module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@7.11.0_gatsby@5.11.0_netlify-cms-app@2.15.72_react-dom@18.2.0_react@18.2.0_webpack@5.88.2/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.1.6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
